function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
/**
 * NOTE: This requires `@sentry/nextjs` version 7.3.0 or higher.
 *
 * NOTE: If using this with `next` version 12.2.0 or lower, uncomment the
 * penultimate line in `CustomErrorComponent`.
 *
 * This page is loaded by Nextjs:
 *  - on the server, when data-fetching methods throw or reject
 *  - on the client, when `getInitialProps` throws or rejects
 *  - on the client, when a React lifecycle method throws or rejects, and it's
 *    caught by the built-in Nextjs error boundary
 *
 * See:
 *  - https://nextjs.org/docs/basic-features/data-fetching/overview
 *  - https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 *  - https://reactjs.org/docs/error-boundaries.html
 */
import * as Sentry from "@sentry/nextjs";
import NextErrorComponent from "next/error";
import type { NextPage } from "next";
import type { ErrorProps } from "next/error";
import Link from "next/link";
import Button from "@a101/components/design-system/components/general/button";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "18s7hpb",
  styles: "width:100%;@media (min-width: 640px){max-width:640px;}@media (min-width: 768px){max-width:768px;}@media (min-width: 1024px){max-width:1024px;}@media (min-width: 1280px){max-width:1280px;}@media (min-width: 1536px){max-width:1536px;}margin-left:auto;margin-right:auto;display:flex;height:100vh;flex-direction:column;align-items:center;justify-content:center;> :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(2rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(2rem * var(--tw-space-y-reverse));}"
} : {
  name: "1h3pwy2-CustomErrorComponent",
  styles: "width:100%;@media (min-width: 640px){max-width:640px;}@media (min-width: 768px){max-width:768px;}@media (min-width: 1024px){max-width:1024px;}@media (min-width: 1280px){max-width:1280px;}@media (min-width: 1536px){max-width:1536px;}margin-left:auto;margin-right:auto;display:flex;height:100vh;flex-direction:column;align-items:center;justify-content:center;> :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(2rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(2rem * var(--tw-space-y-reverse));};label:CustomErrorComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1mfsarq",
  styles: "padding-left:2rem;padding-right:2rem;text-align:center;font-family:Qanelas;font-weight:700;color:var(--color-primary);@media (min-width: 768px){font-size:1.125rem;}@media (min-width: 1280px){font-size:1.5rem;}"
} : {
  name: "1aahzxw-CustomErrorComponent",
  styles: "padding-left:2rem;padding-right:2rem;text-align:center;font-family:Qanelas;font-weight:700;color:var(--color-primary);@media (min-width: 768px){font-size:1.125rem;}@media (min-width: 1280px){font-size:1.5rem;};label:CustomErrorComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const CustomErrorComponent: NextPage<ErrorProps> = props => {
  const {
    statusCode
  } = props;
  console.error(props);
  return <div css={_ref}>
			<p css={_ref2}>
				{statusCode ? `Oh no! A ${statusCode} error occurred on the Alcohol101+ server` : `Oh no! An error occurred while using Alcohol101+`}
			</p>
			<Link href="/">
				<Button color="primary" label="Back Home" />
			</Link>
		</div>;
};
CustomErrorComponent.getInitialProps = async contextData => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return NextErrorComponent.getInitialProps(contextData);
};
export default CustomErrorComponent;